import React, { useEffect } from "react";
import { notification, Modal } from "antd";

const Notifications = ({ flash }) => {
  useEffect(() => {
    if (flash) {
      flash.forEach((item) => {
        const [notice_type, notice_message] = item;
        switch (notice_type) {
          default:
            notification.open({ message: notice_message });
            break;
          case "info":
            notification.info({ message: notice_message });
            break;
          case "success":
            notification.success({ message: notice_message });
            break;
          case "warning":
            notification.warning({ message: notice_message });
            break;
          case "error":
            notification.error({ message: notice_message });
            break;
          case "modal":
            for (const [modal_type, modal_message] of Object.entries(notice_message)) {  
              switch (modal_type) {
                default:
                  Modal.confirm({ content: modal_message, okText: "確認" });
                  break;
                case "info":
                  Modal.info({ content: modal_message, okText: "確認" });
                  break;
                case "success":
                  Modal.success({ content: modal_message, okText: "確認" });
                  break;
                case "warning":
                  Modal.warning({ content: modal_message, okText: "確認" });
                  break;
                case "error":
                  Modal.error({ content: modal_message, okText: "確認" });
                  break;
              }
            }
            break;
        }
      });
    }
  }, []);
  return <></>;
};

export default Notifications;
