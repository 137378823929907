import React, { useState } from "react";
import { Typography, Card, Form, notification, Modal } from "antd";
import { signup, login } from '../../services/users'
import NewSignUpForm from "./NewSignUpForm";
import {micromark} from 'micromark';
import './styles.scss'

const NewUserV2 = ({
  csrf_token, 
  incentive_store_hash_code,
  incentive_staff_hash_code
}) => {
  const [isLoading, setLoading] = useState(false);
  const [registrerForm] = Form.useForm();
  const [loginForm] = Form.useForm();

  const notify_signup_error = (error) => {
    error = micromark(error);
    error = error.replace("\\n", "<br/>");
    Modal.error({
      title: window.i18n.t("new_user.new_user_error"),
      content: <><div dangerouslySetInnerHTML={{ __html: error }} /></>,
      okText: window.i18n.t("new_user.confirm"),
    });
  }

  const notify_signin_error = (error) => Modal.error({
    title: window.i18n.t("new_user.signin_error"),
    content: error,
    okText: window.i18n.t("new_user.confirm"),
  });

  const onFinishSignUp = async (values) => {
    setLoading(true);
    try {
      const res = await signup({
        user: values
      })
      if (res?.data?.success) {
        window.location.href = `${window.location.origin}/ec_users`
      } else {
        notify_signup_error(res?.data?.message);
        setLoading(false);
      }
    } catch (error) {
      notify_signup_error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const onFinishSignIn = async (values) => {
    setLoading(true);
    try {
      const res = await login({
        user: values
      })
      if (res?.data?.success) {
        if (res?.data?.redirectTo) {
          window.location.href = res?.data?.redirectTo
        } else {
          window.location.href = `${window.location.origin}`
        }
      } else {
        notify_signin_error(res?.data?.message);
        setLoading(false);
      }
    } catch (error) {
      notify_signin_error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  return (
    <Card className="sign-in-card">
      <Typography.Title level={3}>
        {window.i18n.t("new_user.new_member_registration")}
      </Typography.Title>
      <NewSignUpForm 
        csrf_token={csrf_token} 
        registrerForm={registrerForm} 
        loginForm={loginForm} 
        onFinishSignUp={onFinishSignUp} 
        onFinishSignIn={onFinishSignIn} 
        incentiveStoreHashCode={incentive_store_hash_code}
        incentiveStaffHashCode={incentive_staff_hash_code}
        isLoading={isLoading}
      />
    </Card>
  );
};

export default NewUserV2;
