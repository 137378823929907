import React from "react";
import {Form, Input, Row, Select } from "antd";
import Button from "../../components/common/ec/Button"
import { t } from '../../helpers/translation';
import styles from './styles';

const InquiryForm = ({ onFinish, form, categories, submitting }) => {
  const { TextArea } = Input;

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
      >
        <Form.Item
          name="customer_name"
          label={t("inquiry.customer_name")}
          validateTrigger="onBlur"
          rules={[
            {
              type: "text",
              message: t("inquiry.not_valid_customer_name"),
            },
            {
              required: true,
              message: t('inquiry.customer_name_required')          
            }    
          ]}
          hasFeedback
        >
          <Input style={styles.inputHeight} />
        </Form.Item>

        <Form.Item
          name="email"
          label={t("inquiry.email")}
          validateTrigger="onBlur"
          rules={[
            {
              type: "email",
              message: t("inquiry.not_valid_email"),
            },
            {
              required: true,
              message: t("inquiry.enter_email"),
            },
          ]}
          hasFeedback
        >
          <Input style={styles.inputHeight} />
        </Form.Item>
        <Form.Item
          name="category"
          label={t("inquiry.category")}
          rules={[
            {
              required: true,
              message: t("inquiry.category_required")
            }
          ]}
          hasFeedback
        >
          <Select>
            {categories.map((item) => (
              <Select.Option key={item[0]} value={item[1]}>{item[0]}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="content"
          label={t("inquiry.content")}
          rules={[
            {
              required: true,
              message: t("inquiry.content_required"),

            },
            {
              max: 1024,
              message: t("inquiry.content_too_long"),

            }
          ]}
          hasFeedback
        >
          <TextArea rows={4} style={{overflow: 'auto'}}/>
        </Form.Item>
        <Form.Item>
          <Row>
            <Button htmlType="submit" disabled={submitting}>
              {window.i18n.t("inquiry.transmittal")}
            </Button>
          </Row>
        </Form.Item>
      </Form>
      <Row>
        <div dangerouslySetInnerHTML={{ __html: t('inquiry.message') }} style={styles.inquiryMessage} className="w-full mb-2">
        </div>
      </Row>
    </>
  );
};

export default InquiryForm;
